import React, { Component } from "react";
import "../../style/common/footerBar.scss";

class FooterBar extends Component {
  constructor(props) {
    super(props);
    this.routePage = this.routePage.bind(this);
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "cookies":
        history.push("/Cookies");
        break;
      case "privacy":
        history.push("/Privacy");
        break;
      case "terms":
        history.push("/Terms");
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <React.Fragment>
        <footer>
          <div className="footer-contain">
            <div className="li-col" onClick={() => this.routePage("cookies")}>
              Cookies
            </div>
            <div className="li-col" onClick={() => this.routePage("privacy")}>
              Privacy
            </div>
            <div className="li-col" onClick={() => this.routePage("terms")}>
              Terms of use
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default FooterBar;
