import React from "react";

const SendMail = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#333",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="35798"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M85.333333 234.666667V170.368A42.666667 42.666667 0 0 1 127.658667 128h768.682666c23.381333 0 42.325333 18.986667 42.325334 42.368v683.264a42.666667 42.666667 0 0 1-42.325334 42.368H127.658667A42.368 42.368 0 0 1 85.333333 853.632V810.666667h768V311.466667l-341.333333 307.2-426.666667-384zM0 426.666667h213.333333v85.333333H0v-85.333333z m0 213.333333h341.333333v85.333333H0v-85.333333z"
      fill={color}
      p-id="35799"
    ></path>
  </svg>
);


export default SendMail;