import React, { Component } from "react";
import "../../style/common/headerBar.scss";

import logo from "../../image/logo.png";
class HeaderBar extends Component {
  constructor(props) {
    super(props);
    this.routePage = this.routePage.bind(this);
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="header">
          <div className="logo" onClick={() => this.routePage("home")}>
            <img src={logo} alt="" />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default HeaderBar;
