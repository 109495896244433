import React from "react";

const SongHuo = ({
  viewBox = "0 0 24 24",
  color = "#8a8a8a",
  size = 24,
  ...props
}) => (
  <svg
    viewBox="0 0 1551 1024"
    p-id="42652"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M378.090186 708.14937H307.891031v-92.367309H215.523721v123.156413a61.578206 61.578206 0 0 0 61.578207 61.578206h100.988258a128.082669 128.082669 0 0 1 0-92.36731zM769.727577 0H277.101928a61.578206 61.578206 0 0 0-61.578207 61.578206v153.945515h92.36731V92.367309h431.047443v615.782061h-100.988259a128.082669 128.082669 0 0 1 0 92.36731H769.727577a61.578206 61.578206 0 0 0 61.578206-61.578206V61.578206a61.578206 61.578206 0 0 0-61.578206-61.578206z"
      fill={color}
      p-id="42653"
    ></path>
    <path
      d="M0 277.101928m46.183655 0l354.074685 0q46.183655 0 46.183654 46.183654l0 0q0 46.183655-46.183654 46.183655l-354.074685 0q-46.183655 0-46.183655-46.183655l0 0q0-46.183655 46.183655-46.183654Z"
      fill={color}
      p-id="42654"
    ></path>
    <path
      d="M785.122128 184.734618m44.028417 0l219.834196 0q44.028417 0 44.028418 44.028418l0 4.310474q0 44.028417-44.028418 44.028418l-219.834196 0q-44.028417 0-44.028417-44.028418l0-4.310474q0-44.028417 44.028417-44.028418Z"
      fill={color}
      p-id="42655"
    ></path>
    <path
      d="M1062.224056 307.891031m42.796853 0l99.140912 0q42.796853 0 42.796853 42.796853l0 6.773603q0 42.796853-42.796853 42.796853l-99.140912 0q-42.796853 0-42.796853-42.796853l0-6.773603q0-42.796853 42.796853-42.796853Z"
      fill={color}
      p-id="42656"
    ></path>
    <path
      d="M901.504938 708.14937H738.938474a46.183655 46.183655 0 0 0 0 92.36731h162.566464a128.082669 128.082669 0 0 1 0-92.36731zM1293.142329 708.14937h-131.777361a128.082669 128.082669 0 0 1 0 92.36731H1293.142329a46.183655 46.183655 0 0 0 0-92.36731z"
      fill={color}
      p-id="42657"
    ></path>
    <path
      d="M1251.578984 303.780201m9.81739 42.288009l91.768226 395.28792q9.81739 42.28801-32.470619 52.1054l-5.39847 1.253284q-42.28801 9.81739-52.105399-32.47062l-91.768227-395.28792q-9.81739-42.28801 32.47062-52.105399l5.398469-1.253284q42.28801-9.81739 52.1054 32.470619Z"
      fill={color}
      p-id="42658"
    ></path>
    <path
      d="M1098.266435 177.106037m10.444032 44.987244l27.850752 119.965985q10.444032 44.987244-34.543213 55.431276l0 0q-44.987244 10.444032-55.431276-34.543212l-27.850751-119.965985q-10.444032-44.987244 34.543212-55.431276l0 0q44.987244-10.444032 55.431276 34.543212Z"
      fill={color}
      p-id="42659"
    ></path>
    <path
      d="M107.761861 461.836546m46.183654 0l277.101928 0q46.183655 0 46.183654 46.183655l0 0q0 46.183655-46.183654 46.183654l-277.101928 0q-46.183655 0-46.183654-46.183654l0 0q0-46.183655 46.183654-46.183655Z"
      fill={color}
      p-id="42660"
    ></path>
    <path
      d="M508.020201 615.782061a138.550964 138.550964 0 1 0 138.550963 138.550964 138.243073 138.243073 0 0 0-138.550963-138.550964z m0 184.734619a46.183655 46.183655 0 1 1 46.183654-46.183655 46.183655 46.183655 0 0 1-46.183654 46.183655zM1031.434953 615.782061a138.550964 138.550964 0 1 0 138.550963 138.550964 138.243073 138.243073 0 0 0-138.550963-138.550964z m0 184.734619a46.183655 46.183655 0 1 1 46.183654-46.183655 46.183655 46.183655 0 0 1-46.183654 46.183655z"
      fill={color}
      p-id="42661"
    ></path>
  </svg>
);

export default SongHuo;
