import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import api from "../apis/API";

export const getTrack = (DanHao) => {
  return api.post(
    `${ApiEndpointConstants.TRACK_DETAIL}`,
    {},
    { params: { DanHao } }
  );
};
