import React from "react";

const PrintIcon = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#5C5C66",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="14651"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M256 256V138.666667a53.393333 53.393333 0 0 1 53.333333-53.333334h405.333334a53.393333 53.393333 0 0 1 53.333333 53.333334v117.333333z m512 672a53.393333 53.393333 0 0 1-53.333333 53.333333H309.333333a53.393333 53.393333 0 0 1-53.333333-53.333333V640h512z m-213.333333-96a21.333333 21.333333 0 0 0-21.333334-21.333333H362.666667a21.333333 21.333333 0 0 0 0 42.666666h170.666666a21.333333 21.333333 0 0 0 21.333334-21.333333z m128 0a21.333333 21.333333 0 0 0-21.333334-21.333333h-42.666666a21.333333 21.333333 0 0 0 0 42.666666h42.666666a21.333333 21.333333 0 0 0 21.333334-21.333333z m0-128a21.333333 21.333333 0 0 0-21.333334-21.333333H362.666667a21.333333 21.333333 0 0 0 0 42.666666h298.666666a21.333333 21.333333 0 0 0 21.333334-21.333333z m298.666666-352v362.666667a53.393333 53.393333 0 0 1-53.333333 53.333333h-117.333333V640h21.333333a21.333333 21.333333 0 0 0 0-42.666667H192a21.333333 21.333333 0 0 0 0 42.666667h21.333333v128H96a53.393333 53.393333 0 0 1-53.333333-53.333333V352a53.393333 53.393333 0 0 1 53.333333-53.333333h832a53.393333 53.393333 0 0 1 53.333333 53.333333z m-85.333333 53.333333a21.333333 21.333333 0 1 0-21.333333 21.333334 21.333333 21.333333 0 0 0 21.333333-21.333334z"
      fill={color}
      p-id="14652"
    ></path>
  </svg>
);

export default PrintIcon;
