import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import api from "./API";

export const Submit = (text) => {
  return api.post(
    `${ApiEndpointConstants.SUBMIT}`,
    {},
    { params: { message: text } }
  );
};
