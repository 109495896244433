import React, { Component } from "react";
import "../style/cookies.scss";
import FooterBar from "./common/FooterBar";
import HeaderBar from "./common/HeaderBar";

class Cookies extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    window.scrollTo(0,0);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="cookie-page">
          <div className="title">Cookies Policy</div>
          <p>
            Royal Mail Group Limited (“we”) uses cookies and similar
            technologies on each website that we operate. The technologies that
            we use include JavaScript tags, pixels, local storage, and web
            beacons, though for simplicity in this Policy we use the generic
            term “cookies” to mean all and any of them.
          </p>
          <p>
            This Policy concerns the royalmail.com domain, and associated
            subdomains, and Royal Mail-branded mobile applications, whose use of
            these technologies is explained further here. The websites which
            this Policy concerns are collectively referred to here as the
            “Website”.
          </p>
          <p>
            We use cookies for a range of purposes. They help us to present the
            Website to you, provide important functions (like our shop), and
            analyse how you arrive at and use the Website. We may also use some
            cookies to tailor adverts to you when you are on the Website, and
            when you are browsing other websites.{" "}
          </p>
          <p>
            Cookies provide us with information about how the Website is used so
            we can keep it as up to date, relevant and error free as possible.
          </p>
          <p>
            Further information about the types of cookies in use on the Website
            is set out below.
          </p>
          <h1>The types and categories of cookies we use</h1>
          <p>
            Cookies enable a small amount of data to be stored on your device
            when you access websites and mobile applications on the internet.
            Cookies can be either:
          </p>
          <p>
            <li>
              Session cookies - these expire when you close your browser and do
              not remain on your device; or
            </li>
          </p>
          <p>
            <li>
              Persistent cookies - these are stored in the longer-term on your
              device. They are normally used to make sure the site remembers
              your preferences.
            </li>
          </p>
          <p>
            The cookies that we use on the Website can be categorised as
            follows:
          </p>
          <p>
            <li>
              "Strictly necessary" cookies are used to enable the basic
              functions of the Website, like security.
            </li>
          </p>
          <p>
            <li>
              "Functional cookies" allow the Website to remember the choices you
              make.
            </li>
          </p>
          <p>
            <li>
              “Performance” cookies support our analysis of the Website’s
              performance; and
            </li>
          </p>
          <p>
            <li>
              “Targeting or advertising” cookies help us and Third Parties to
              personalise content and tailor advertisements for you.
            </li>
          </p>
          <p>
            You can find more information about each of these categories of
            cookie below.
          </p>
          <h1>Your choices in relation to cookies</h1>
          <p>
            When you first visit the Website, you will be asked to confirm
            whether you agree to functional, performance and
            targeting/advertising cookies being placed on your device. You can
            change your choices at any time by clicking on the “Change Consent
            Preferences” link at the bottom of the Website.
          </p>
          <p>
            At the same time, a small number of strictly necessary cookies will
            be placed on your device automatically in order to allow the Website
            to function.{" "}
          </p>
          <p>
            Please note that if you choose not to accept cookies, or if you
            configure your browser settings to reject all cookies (including
            strictly necessary cookies), certain functions and services on the
            Website may not be available to you. This will also affect our
            ability to update the Website to cater for your preferences and
            improve the Website’s performance.
          </p>
          <p>
            The types of cookies we currently use on the Website are set out in
            more detail below:
          </p>
          <h1>Strictly necessary cookies</h1>
          <p>
            Strictly necessary cookies are used to enable the basic functions of
            this website like security. As these cookies are essential, they
            cannot be switched off.{" "}
          </p>
          <p>The Website uses strictly necessary cookies to:</p>
          <p>
            <li>Identify you as being logged in to the Website</li>
          </p>
          <p>
            <li>
              Remember the goods and services you ordered when you get to the
              checkout page
            </li>
          </p>
          <p>
            <li>
              Remember things like information you've entered on order forms
              when you navigate to different pages during your session on the
              Website
            </li>
          </p>
          <p>
            <li>
              Remember things like service selections you have made which are
              essential for us to provide you with services you have requested
            </li>
          </p>
          <p>
            <li>
              Make sure you connect to the right service on the Website when we
              make any changes to the way the Website works
            </li>
          </p>
          <p>
            <li>
              Identify you as having consented to use of other cookies on the
              Website
            </li>
          </p>
          <h1>Functional cookies</h1>
          <p>
            Functional cookies are used to remember information you provide when
            you browse the Website, as well as your choices. For example, they
            are used to store your username, log in details and language
            preferences, and any customisations you make to Website pages during
            your visit. They are necessary to provide features and services
            specific to individual users.
          </p>
          <p>The Website uses functional cookies to:</p>
          <p>
            <li>
              Remember settings you've applied (such as layout, text size,
              preferences and colours)
            </li>
          </p>
          <p>
            <li>
              Remember selections you have made (such as search functions and
              results, and goods and services)
            </li>
          </p>
          <p>
            <li>Apply customised content segments to individual users</li>
          </p>
          <p>
            <li>
              Apply users' individual service specifications (such as usage or
              credit allowance)
            </li>
          </p>
          <p>
            <li>
              Share information with our trusted third parties' who helps us to
              provide a service on the Website. The information shared is only
              to be used to provide the service, product or function and not for
              any other purpose
            </li>
          </p>
          <p>
            <li>
              Enable live help services, use of map and location services, video
              content for playback and site search functionality.
            </li>
          </p>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Cookies;
