import React from "react";

const Position = ({
    viewBox = '0 0 24 24',
    size = 24,
    color = "#8a8a8a",
    ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="49015"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M512 691.72V285.24l-246.35 492.7L512 691.72zM137.98 849.83l344.67-689.34c8.1-16.21 27.81-22.78 44.02-14.68 6.35 3.18 11.5 8.32 14.68 14.68l344.67 689.34c8.1 16.21 1.53 35.93-14.68 44.03a32.8 32.8 0 0 1-25.51 1.62L512 778.64 178.17 895.48c-17.11 5.99-35.83-3.03-41.82-20.13a32.823 32.823 0 0 1 1.63-25.52z"
      fill={color}
      p-id="49016"
    ></path>
  </svg>
);


export default Position;