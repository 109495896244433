import React from "react";
const EmailIcon = ({
  size = 24,
  color = "#bfbfbf",
  viewBox = "0 0 24 24",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="19123"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M896 768 896 283.6 633.8 483.2 770 638 766 642 608.2 502.8 512 576 415.8 502.8 258 642 254 638 390 483.2 128 284 128 768Z"
      p-id="19124"
      fill={color}
    ></path>
    <path d="M879.4 256 144 256 512 535.8Z" p-id="19125" fill={color}></path>
  </svg>
);

export default EmailIcon;
