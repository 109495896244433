import React, { Component } from "react";
import "../style/terms.scss";
import FooterBar from "./common/FooterBar";
import HeaderBar from "./common/HeaderBar";

class Terms extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    window.scrollTo(0,0);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="term-page">
          <div className="title">Royal Mail website terms of use</div>
          <h1>1. Your use of this website</h1>
          <p>
            1.1 These terms and conditions set out the terms on which you may
            use www.grmtoexpress.com (this website).
          </p>
          <p>
            1.2 By using this website, you accept these terms and conditions of
            use. If you do not agree with these terms and conditions, you should
            stop using this website.
          </p>
          <p>
            1.3 In these terms and conditions, Royal Mail, we or us means Royal
            Mail Group Limited and its related group companies.
          </p>
          <h1>2. Information about us</h1>
          <p>
            2.1 This website is owned and operated by Royal Mail Group Limited.
          </p>
          <p>
            2.2 Royal Mail Group Limited is a company registered in England and
            Wales. Our registered number is 4138203 and our registered office is
            at:
            <br />
            185 Farringdon Road London EC1A 1AA
          </p>
          <p>2.3 Our VAT registration number is GB243170002.</p>
          <h1>3. Security and privacy</h1>
          <p>
            3.1 We take the security and privacy of our systems and our
            customers' information very seriously. We review our systems and
            data to ensure the best possible service to our customers. There are
            specific offences for unauthorised actions against computer systems
            and data, and we will investigate any such actions with a view to
            prosecuting and/or taking civil proceedings to recover damages
            against those responsible.
          </p>
          <p>
            3.2 By using this website, you agree to the collection and use of
            your data in accordance with the terms of our Privacy Policy, and
            the specific customer terms and conditions relevant to the different
            products and services available through this website.
          </p>
          <h1>4. Content and accuracy of information</h1>
          <p>
            4.1 The information on this website is provided on an "as is" basis.
            Although we make reasonable efforts to ensure that the information
            on this website is correct, we give no representations, warranties
            or guarantees that the content of this website is accurate, complete
            or up to date.
          </p>
          <p>
            4.2 Some information on this website is taken from sources external
            to us or is of a type that changes frequently. In particular, we do
            not guarantee the accuracy and currency of information about prices,
            compensation limits and conditions, delivery times, packaging
            requirements, posting restrictions and prohibitions and conditions
            of posting in countries outside the United Kingdom.
          </p>
          <p>
            4.3 We do not necessarily share the opinions expressed on, or
            endorse the material appearing in, external content published on
            this website.
          </p>
          <p>
            4.4 We may make changes to the contents of this website, including
            to the descriptions and prices of goods and services advertised, at
            any time and without notice.
          </p>
          <p>
            4.5 Personalisation of the site based on user activity and
            preferences may change the appearance and contents of this website
            at any time.{" "}
          </p>
          <h1>5. Our liability</h1>
          <p>
            5.1 We do not exclude or limit our liability for death or personal
            injury caused by our negligence.
          </p>
          <p>
            5.2 To the fullest extent permitted by law, we: <br /> 5.2.1 exclude
            all representations and warranties relating to this website and its
            contents, including in relation to any inaccuracies or omissions in
            this website; and <br /> 5.2.2 exclude all liability for loss or
            damage arising out of or in connection with your use of this
            website. This includes, without limitation, direct loss, loss of
            business or profits (whether or not the loss of such profits was
            foreseeable, arose in the normal course of things or you have
            advised us of the possibility of such potential loss) and indirect,
            consequential and incidental damages.
          </p>
          <p>
            5.3 We will not be responsible for any loss or damage arising under
            or in connection with your use of this website or your use of, or
            reliance on, any of the content contained on this website.
          </p>
          <p>
            5.4 The above exclusions and limitations apply only to the extent
            permitted by law. None of your statutory rights as a consumer that
            cannot be excluded or limited are affected.
          </p>
          <h1>6. Intellectual property rights</h1>
          <p>
            6.1 All intellectual property rights in this website, and the
            material contained on it, are owned or licensed by us.
          </p>
          <p>
            6.2 Royal Mail, the Royal Mail cruciform, Parcelforce Worldwide and
            the Parcelforce Worldwide logo are registered trademarks of Royal
            Mail Group Limited or its related companies in the United Kingdom
            and other countries.
          </p>
          <p>
            6.3 The names of our goods and services featured on this website are
            trade marks of Royal Mail Group Limited or its related companies.
          </p>
          <p>
            6.4 All website design, text, graphics, their selection and
            arrangement, and all software compilations, underlying source code,
            software (including applets) and all other material on this website
            are copyright of Royal Mail Group Limited or its related companies
            or licensors.
          </p>
          <h1>7. Contracts</h1>
          <p>
            7.1 The products or services on sale on this website are subject to
            our shop terms and conditions and any specific terms applicable to
            the relevant product or service.
          </p>
          <h1>8. Registration</h1>
          <p>
            8.1 You may use this website to register for an account with us.
          </p>
          <p>
            8.2 If you choose, or you are provided with, a user identification
            code, password or any other piece of information as part of our
            security procedures, you must treat such information as
            confidential. You must not disclose it to any third party.
          </p>
          <p>
            8.3 We have the right to disable any user identification code or
            password, whether chosen by you or allocated by us, at any time, if
            in our reasonable opinion you have failed to comply with any of the
            provisions of these terms and conditions.
          </p>
          <h1>9. Discussion groups and bulletin boards</h1>
          <p>
            9.1 We do not usually edit or monitor the content of any discussion
            group or bulletin board hosted on this website. The content of
            discussion groups and bulletin boards is provided by third parties
            and we are not the publisher of it. However, we may remove content
            from any discussion group or bulletin board at our discretion.
          </p>
          <h1>10. Links to this website</h1>
          <p>
            10.1 You may not create a link to any page of this website without
            our prior written consent. To request our consent, please contact
            our Brand team at brand.team@royalmail.com
          </p>
          <p>
            10.2 If we do not consent and you create a link to a page of this
            website, you do so at your own risk and the exclusions and
            limitations set out in these terms and conditions will apply to your
            use of this website by linking to it.
          </p>
          <h1>11. Links from this website</h1>
          <p>
            11.1 We do not monitor or review the content of other parties'
            websites that are linked from this website. Opinions expressed or
            material appearing on such websites are not necessarily shared or
            endorsed by us. We are not the publisher of such opinions or
            material.
          </p>
          <h1>12. Third party advertising</h1>
          <p>
            12.1 Pages on this website may contain advertisements placed by
            third parties. We are not responsible for the content of these
            advertisements. Clicking on the links contained on these
            advertisements may take you to third party websites over which we
            have no control. We are not responsible for the content of these
            third party websites.
          </p>
          <h1>13. Availability</h1>
          <p>
            13.1 We do not guarantee that this website will always be available
            or uninterrupted.
          </p>
          <p>
            13.2 Some products and services featured on this website will only
            be available within the United Kingdom, or in relation to postings
            from the United Kingdom.
          </p>
          <h1>14. Downloadable content</h1>
          <p>
            14.1 We will use reasonable endeavours to ensure that any content we
            make available on this website for downloading (including software
            or any electronic file) is suitable for downloading, installation
            and use by third parties.
          </p>
          <p>
            14.2 All content we make available for downloading is provided "as
            is" without any warranty. Specifically, and without limitation, we
            do not warrant that any such content is virus free, without defects,
            compatible with other software or operating systems or suitable for
            any specific purpose.
          </p>
          <p>
            14.3 We accept no liability for any loss or damage caused by the
            downloading, installation or use of any such content, and the
            general exclusions and limitations set out in the 'Our Liability'
            section of these terms and conditions shall apply to the
            downloading, installation or use of such content.{" "}
          </p>
          <h1>15. General</h1>
          <p>
            15.1 These terms and conditions are governed by the laws of England
            and Wales.
          </p>
          <p>
            15.2 If you are a consumer, all disputes arising out of your access
            to this website are subject to the non-exclusive jurisdiction of the
            courts of England and Wales. If you are a resident of Northern
            Ireland, you may also bring proceedings in Northern Ireland, and if
            you are a resident of Scotland, you may also bring proceedings in
            Scotland. If you are a business, all disputes arising out of your
            access to this website are subject to the exclusive jurisdiction of
            the English courts.
          </p>
          <p>
            15.3 If any of these terms or conditions is deemed invalid or
            unenforceable for any reason (including, but not limited to, the
            exclusions and limitations set out above) then the invalid or
            unenforceable provision will be severed from these terms and
            conditions and the remaining provisions will continue to apply.
          </p>
          <p>
            15.4 These terms and conditions were last updated 14 July 2014. We
            may change these terms and conditions at any time by amending this
            page, and so you should check back to this page regularly.
          </p>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Terms;
