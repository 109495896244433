import React from "react";

const CheckOrder = ({
  viewBox = "0 0 24 24",
  color = "#135917",
  size = 24,
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="42050"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M544.234145 146.178267c-202.342428 0-366.335433 163.993005-366.335433 366.335433 0 202.343451 163.993005 366.332363 366.335433 366.332363 202.366987 0 366.331339-163.988912 366.331339-366.332363C910.565485 310.171272 746.577596 146.178267 544.234145 146.178267L544.234145 146.178267zM475.964272 690.441065 312.25984 526.736633l37.042661-37.042661L475.964272 616.355743l263.221983-263.225053 37.042661 37.042661L475.964272 690.441065 475.964272 690.441065zM475.964272 690.441065"
      fill={color}
      p-id="42051"
    ></path>
  </svg>
);

export default CheckOrder;
