import React from "react";

const SalesReturn = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#333",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="18591"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M960 320v512a64 64 0 0 1-64 64H128a64 64 0 0 1-64-64V320h896zM480 64v192h64V64h-64zM576 576a64 64 0 1 1 0 128H320v64h256a128 128 0 1 0 0-256H448V448L256 544 448 640V576h128z m275.84-512L960 256H64l132.352-192H851.84z"
      fill={color}
      p-id="18592"
    ></path>
  </svg>
);

export default SalesReturn;
