import React from "react";

const AllowDown = ({
    viewBox= '0 0 24 24',
    color = "#666666",
    size = 24,
    ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="16153"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M225.834667 353.834667a42.666667 42.666667 0 0 1 60.330666 0L512 579.669333l225.834667-225.834666a42.666667 42.666667 0 1 1 60.330666 60.330666l-256 256a42.666667 42.666667 0 0 1-60.330666 0l-256-256a42.666667 42.666667 0 0 1 0-60.330666z"
      fill={color}
      p-id="16154"
    ></path>
  </svg>
);

export default AllowDown;