import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/privacy.scss";
import crystal_mark from "../image/crystal_mark.jpg";

class Privacy extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    window.scrollTo(0,0);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="privacy-page">
          <div className="title">Royal Mail Group privacy notice</div>
          <img src={crystal_mark} alt="" />
          <p>
            We are committed to respecting your privacy and protecting your
            personal information (data) in line with current data protection
            laws. This privacy notice explains how we collect and use your
            personal data and tells you about your privacy rights.
          </p>
          <p>
            We’ve split the information into sections so that you can click
            through to the areas you need.
          </p>
          <p>About us and our privacy notice</p>
          <p>
            This notice explains how we, Royal Mail Group Limited, use your
            personal data.{" "}
          </p>
          <p>
            Personal data is information which relates to a person who has been,
            or could be, identified from it. Examples may include a person’s
            name, address, email address and other personal details.{" "}
          </p>
          <p>
            Royal Mail Group Limited includes the Royal Mail and Parcelforce
            Worldwide businesses and any subsidiaries. The personal data of
            yours that we use includes:{" "}
          </p>
          <p>
            <li>
              details we collect about you when you use our websites and apps
            </li>
          </p>
          <p>
            <li>
              information provided when you use or receive our services, and
            </li>
          </p>
          <p>
            <li> information we receive from other sources. </li>
          </p>
          <p>
            We will usually be the ‘data controller’ responsible for how and why
            personal data is used. We may sometimes use personal data on behalf
            of another organisation, as that organisation’s ‘data processor’. In
            this case, that other organisation will explain how your personal
            data could be used.
          </p>
          <p>
            Our address is: Royal Mail Group Limited 185 Farringdon Road London
            EC1A 1AA
          </p>
          <p>
            When you use or receive our products or services, we will sometimes
            need to give you additional information about how we will use your
            personal data. You should read this privacy notice with any other
            notice or information we provide.{" "}
          </p>
          <p>
            This privacy notice applies if you use any of our products or
            services. This includes using any of our websites (such as
            royalmail.com, royalmailgroup.com or parcelforce.com) or apps. This
            policy also applies if you contact us or we contact you about our
            services by post, phone, email, text message, push notifications
            (automatic messages sent by apps) or other methods (including posts
            on websites and social-media platforms).
          </p>
          <p>Information about marketing</p>
          <p>Marketing</p>
          <p>
            We would like to keep in touch with you, by post, email, phone,
            social media or online, about our products, services and offers that
            might interest you. You can choose whether you want to receive these
            communications and can opt out at any time.{" "}
          </p>
          <p>
            We have a legitimate interest in promoting our products and
            services, but if you ask us not to send you marketing
            communications, we won't send them. In particular, when we collect
            contact information that we would like to use to send you marketing,
            we will give you the opportunity, at that time, to tell us not to
            send it.{" "}
          </p>
          <p>Making sure our marketing messages are relevant to you </p>
          <p>
            We want to make sure the information we send you about our products
            and services is relevant to you. To do this, we may use your
            personal data to help us understand your interests better. We may
            also use details of your computer, laptop, smartphone or tablet
            (including its IP address) to check you are linked to the right web
            page after clicking on one of our online adverts. If you visit our
            websites, we may also use cookies to tell us which marketing to send
            you. Please see section 11 below for information about cookies.
          </p>
          <p>
            These approaches help us to make our communications more relevant
            and interesting for you. Looking at the web pages you’ve visited and
            the things you’ve bought helps us to understand you as a customer
            better and allows us to provide personalised services, content,
            communications and online advertising. We may also measure how you
            respond to our marketing communications to help us understand how
            effective they are and offer you products and services that better
            meet your needs.{" "}
          </p>
          <p>
            If you don’t want us to personalise our marketing messages to you in
            this way, you can opt out of personalisation. If you do this, we
            will not send you any more marketing communications. Please see
            section 11 below to find out more about how you can control whether
            we use cookies in marketing to you.
          </p>
          <p>Tracking technology</p>
          <p>
            We use industry-standard technology to monitor your engagement with
            marketing communications and send emails automatically, so that we
            can send you relevant emails at the right time. We use ‘tracking
            pixels’ to recognise when you have opened an email that we sent to
            you. If you don’t want us to use tracking pixels, you can use your
            email settings so that images are not automatically displayed.
          </p>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Privacy;
