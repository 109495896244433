import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  HashRouter,
  Route,
  BrowserRouter,
} from "react-router-dom";

import Popup from "./components/common/Popup";
import Home from "./components/Home";
import Cookies from "./components/Cookies";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <BrowserRouter>
        <React.Fragment>
          <Popup {...this.props} />
          <Switch>
            <Route path="/Privacy" component={Privacy}></Route>
            <Route path="/Terms" component={Terms}></Route>
            <Route path="/Cookies" component={Cookies}></Route>
            <Route path="/" component={Home}></Route>
          </Switch>
        </React.Fragment>
      </BrowserRouter>
    );
  }
}
export default App;
