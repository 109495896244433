import React, { Component } from "react";
import "../style/home.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/scss/autoplay";
import img1 from "../image/1.png";
import img2 from "../image/2.png";
import img3 from "../image/3.jpg";
import SubmitIcon from "../icon/SubmitIcon";
import SearchIcon from "../icon/SearchIcon";
import PrintIcon from "../icon/PrintIcon";
import AllowDown from "../icon/DownAllowIcon";
import AllowUp from "../icon/AllowUp";
import CheckOrder from "../icon/CheckOrder";
import Position from "../icon/Position";
import * as TrackAPI from "../apis/TrackAPI";
import Loading from "../components/common/Loading";
import SalesReturn from "../icon/SalesReturn";
import SongHuo from "../icon/SongHuo";
import SendMail from "../icon/SendMail";
import queryString from "query-string";
import ServiceIcon from "../icon/ServiceIcon";
import profileImg from "../image/profileImg.jpg";
import hello from "../image/hello.png";
import AllowDown2 from "../icon/AllowDown";
import EmailIcon from "../icon/EmailIcon";
import ellipsis from "../image/ellipsis.gif";
import Submit from "../icon/SubmitIcon";
import * as SubmitAPI from "../apis/SubmitAPI";
import FooterBar from "./common/FooterBar";
import HeaderBar from "./common/HeaderBar";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerList: [{ img: img2 }],
      shipList: [],
      shipmentShow: false,
      shipUpdateShow: true,
      DanHao: "",
      loadingShow: false,
      track: {},
      code: "",
      message: [],
      showService: false,
      text: "",
    };
    this.changeShipmentShow = this.changeShipmentShow.bind(this);
    this.changeShipUpdateShow = this.changeShipUpdateShow.bind(this);
    this.changeInput = this.changeInput.bind(this);
    this.searchTrack = this.searchTrack.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.print = this.print.bind(this);
    this.showService = this.showService.bind(this);
    this.createMessageLi = this.createMessageLi.bind(this);
    this.changeContent = this.changeContent.bind(this);
    this.changeMessageInput = this.changeMessageInput.bind(this);
    this.submit = this.submit.bind(this);
    this.messageEndRef = React.createRef();
  }

  componentDidMount() {
    let { location } = this.props;
    if (location.search !== "") {
      let arr = queryString.parse(location.search);
      let danHao = arr.trackId;
      this.setState({ DanHao: danHao }, () => {
        this.searchTrack();
      });
    }
  }

  changeShipmentShow() {
    this.setState({ shipmentShow: !this.state.shipmentShow });
  }

  changeShipUpdateShow() {
    this.setState({ shipUpdateShow: !this.state.shipUpdateShow });
  }

  changeInput(e) {
    this.setState({ DanHao: e.target.value });
  }

  searchTrack() {
    const { DanHao } = this.state;
    this.toggleShow();
    TrackAPI.getTrack(DanHao).then((res) => {
      this.toggleShow();
      var code = res.data.code;
      if (code === 200) {
        var data = res.data.data;
        console.log(data);
        data.list.map((item) => {
          item.time = item.wuLiuShiJian.split("T");
        });
        data.updatetime = data.updatetime.split("T");
        data.time = data.updatetime[0];
        data.times = data.updatetime[1];
        data.shouHuoDiZhi = data.shouHuoDiZhi.split(",");
        let code = Math.round(Math.random() * 1000000000000);
        this.setState({ track: data, shipList: data.list, code });
      }
    });
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  print() {
    window.print();
  }

  showService() {
    this.setState({
      showService: !this.state.showService,
      showSafe: false,
    });
  }

  createMessageLi(type) {
    let { message } = this.state;
    let obj = {};
    obj.l = `<img src=${ellipsis}>`;
    obj.r = null;
    message.push(obj);
    this.setState({ message }, () => {
      this.scrollToBottom();
      setTimeout(() => {
        this.changeContent(type);
      }, 3000);
    });
  }

  changeContent(type) {
    let { message } = this.state;
    message.splice(message.length - 1, 1);
    let obj = {};
    switch (type) {
      case "order":
        obj.l = `<div>Please send your trcking number here.</div>`;
        obj.r = null;
        break;
      case "email":
        obj.l = `<div>Please send your email here.</div>`;
        obj.r = null;
        break;
      default:
        break;
    }
    message.push(obj);
    this.setState({ message });
  }

  changeMessageInput(e) {
    this.setState({ text: e.target.value });
  }

  submit() {
    let { text, message } = this.state;
    if (text !== "") {
      console.log(text);
      let obj = {};
      obj.r = text;
      obj.l = null;
      message.push(obj);
      this.setState({ message }, () => {
        SubmitAPI.Submit(text).then((res) => {
          console.log(res);
        });
        this.setState({ text: "" });
        this.scrollToBottom();
        let obj = {};
        obj.l = `<img src=${ellipsis}>`;
        obj.r = null;
        message.push(obj);
        this.setState({ message }, () => {
          setTimeout(() => {
            message.splice(message.length - 1, 1);
            let obj = {};
            obj.l = `<div>We already get your message and we will let our staff contact you in 24 hours!</div>`;
            obj.r = null;
            message.push(obj);
            this.setState({ message });
          }, 3000);
        });
      });
    }
  }

  scrollToBottom() {
    this.messageEndRef.current.scrollIntoView({ behavior: "smooth" });
  }

  render() {
    const {
      bannerList,
      shipList,
      shipmentShow,
      shipUpdateShow,
      track,
      code,
      DanHao,
      showService,
      message,
      text,
    } = this.state;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        {/* service */}
        <div className="service">
          <div className="service-icon" onClick={this.showService}>
            <ServiceIcon size={30} color="#112d57" />
          </div>
          <div className={`service-box ${showService ? "service-show" : ""}`}>
            <div className="head">
              <div className="head-bar">
                <div className="left">
                  <div className="profile">
                    <img src={profileImg} alt="" />
                  </div>
                  <div className="text">
                    Hi there !{" "}
                    <div className="img">
                      <img src={hello} alt="" />
                    </div>
                  </div>
                </div>
                <AllowDown2 size={30} color="#fff" onClick={this.showService} />
              </div>
              <div className="bottom-bar">
                <div className="dian"></div>
                <div className="txt">We are offline</div>
              </div>
            </div>
            <div
              className={`content ${message.length > 0 ? "" : "chatWindow"}`}
            >
              <div className="contact-box">
                <div className="message-head">
                  Hi! What brings you here today? We'll be happy to assist you!
                </div>
                <div
                  className="message-li"
                  onClick={() => this.createMessageLi("order")}
                >
                  <SearchIcon size={14} color="#165ac0" /> Track the Order
                </div>
                <div
                  className="message-li"
                  onClick={() => this.createMessageLi("email")}
                >
                  <EmailIcon size={24} color="#bfbfbf" />
                  Contact Support
                </div>
              </div>
              <div className="message-box">
                {message.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="li">
                        {item.r !== null ? (
                          <div
                            className="r"
                            dangerouslySetInnerHTML={{ __html: item.r }}
                          ></div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="l2">
                        {item.l !== null ? (
                          <div
                            className="l"
                            dangerouslySetInnerHTML={{ __html: item.l }}
                          ></div>
                        ) : (
                          ""
                        )}
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
              <div ref={this.messageEndRef} />
            </div>
            <div
              className={`footer ${message.length > 0 ? "" : "inputHidden"}`}
            >
              <input
                type="text"
                value={text || ""}
                onChange={(e) => this.changeMessageInput(e)}
              />
              <Submit
                size={20}
                color="#165ac0"
                className="icon"
                onClick={this.submit}
              />
            </div>
          </div>
        </div>
        <div className="home-page">
          <HeaderBar {...this.props} />
          {/* swiper */}
          <div className="swiper-row">
            <Swiper
              modules={[Navigation, Pagination, Autoplay]}
              spaceBetween={100}
              slidesPerView={1}
              // autoplay={{ delay: 3000 }}
              navigation
              // pagination={{ clickable: true }}
            >
              {bannerList.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <img src={item.img} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div className="sec2">TRACK: EXPRESS</div>
          <div className="delivery-box">
            <div className="delivery-content">
              <div className="search-box">
                <div className="left">
                  <input
                    type="text"
                    value={DanHao || ""}
                    placeholder="Enter your tracking numbers ..."
                    onChange={(e) => this.changeInput(e)}
                  />
                </div>
                <div className="btn" onClick={this.searchTrack}>
                  <span>Track</span> <SearchIcon size={20} color="#fff" />
                </div>
              </div>
              {shipList.length > 0 ? (
                <div className="shipping-box">
                  <div className="ship-head">
                    <div className="info">
                      <div className="li-code">
                        Tracking Code : <span>{code}</span>
                      </div>
                      <div className="li">
                        This shipment is handled by : <span>GRM express</span>
                      </div>
                    </div>
                    <div className="btn-show" onClick={this.print}>
                      <span> Print</span>{" "}
                      <PrintIcon size={16} color="rgb(212, 5, 17)" />
                    </div>
                  </div>
                  <div className="delivery-head">
                    <div className="title">
                      {shipList && shipList.length === 15
                        ? "Delivered"
                        : "On the way "}
                    </div>
                    <div className="time">
                      {shipList[0].time[0]} {shipList[0].time[1]} Local time,
                      Service Area: <span> {track.shouHuoDiZhi[1]}</span>
                    </div>
                    <div className="delivery-ship">
                      <div className="original">
                        Origin Service Area : SINGAPORE
                      </div>
                      <div className="line">
                        <div className="line-li"></div>
                        <div className="line-li"></div>
                        <div className="line-li"></div>
                      </div>
                      <div className="current">
                        Destination Service Area : {track.shouHuoDiZhi[1]}
                      </div>
                    </div>
                  </div>
                  <div className="shipment-detail">
                    <div
                      className={`shipment-header ${
                        shipmentShow ? "active" : ""
                      }`}
                      onClick={this.changeShipmentShow}
                    >
                      More Shipment Detail
                      {shipmentShow ? (
                        <AllowUp size={20} color="rgb(212, 5, 17)" />
                      ) : (
                        <AllowDown size={20} color="#000" />
                      )}
                    </div>
                    {shipmentShow ? (
                      <div className="shipment-body">
                        <div className="head1">
                          <div className="title">
                            To protect your privacy, more delivery details are
                            available after validation
                          </div>
                          <div className="value">
                            Electronic Proof of Delivery
                          </div>
                        </div>
                        <div className="id-head">
                          <div className="title">1 Piece ID</div>
                          <div className="value">{track.danHao}</div>
                        </div>
                        {/* <div className="delivery-num">
                          <div className="title">Waybill Number</div>
                          <div className="value">9665722872</div>
                        </div> */}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="ship-update-box">
                    <div
                      className={`update-head ${
                        shipUpdateShow ? "active" : ""
                      }`}
                      onClick={this.changeShipUpdateShow}
                    >
                      All Shipment Updates
                      {shipUpdateShow ? (
                        <AllowUp size={20} color="rgb(212, 5, 17)" />
                      ) : (
                        <AllowDown size={20} color="#000" />
                      )}
                    </div>
                    {shipUpdateShow ? (
                      <div className="update-body">
                        <div className="update-box-head">
                          <div className="time-box">
                            <div className="day"></div>
                            <div className="date">{shipList[0].time[0]}</div>
                            <div className="time">
                              {shipList[0].time[1]} Local time
                            </div>
                          </div>
                          <div className="info-box">
                            <div className="status">
                              {shipList[0].wuLiuZhuangTai}
                            </div>
                            <div className="ship-txt">
                              {shipList[0].wuLiuXinXi}
                            </div>
                            <div className="id">
                              1 Piece ID: <span>{track.danHao}</span>
                            </div>
                            <CheckOrder
                              size={40}
                              color="#67a31d"
                              className="status-icon"
                            />
                          </div>
                        </div>
                        {shipList.map((ship, index) => {
                          return (
                            <React.Fragment key={index}>
                              {index === shipList.length - 1 ? (
                                <div className="update-footer">
                                  <div className="time-box">
                                    <div className="day"></div>
                                    <div className="date">{ship.time[0]}</div>
                                    <div className="time">
                                      {ship.time[1]} Local time
                                    </div>
                                  </div>
                                  <div className="info-box">
                                    <div className="status">
                                      {ship.wuLiuZhuangTai}
                                    </div>
                                    <div className="ship-txt">
                                      {ship.wuLiuXinXi}
                                    </div>
                                    <div className="id">
                                      1 Piece ID: <span>{track.danHao}</span>
                                    </div>
                                    <Position size={25} className="li-icon" />
                                  </div>
                                </div>
                              ) : (
                                <div className="update-li">
                                  <div className="time-box">
                                    <div className="day"></div>
                                    <div className="date">{ship.time[0]}</div>
                                    <div className="time">
                                      {ship.time[1]} Local time
                                    </div>
                                  </div>
                                  <div className="info-box">
                                    <div className="status">
                                      {ship.wuLiuZhuangTai}
                                    </div>
                                    <div className="ship-txt">
                                      {ship.wuLiuXinXi}
                                    </div>
                                    <div className="id">
                                      1 Piece ID: <span>{track.danHao}</span>
                                    </div>
                                    <Position size={25} className="li-icon" />
                                  </div>
                                </div>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="sec1">
            <div className="title">What can we help you with today ?</div>
            <ul>
              <li>
                <div className="head-title">Sending mail</div>
                <p className="text">Postage prices and options</p>
                <SendMail size={25} color="#bfbfbf" />
              </li>
              <li>
                <div className="head-title">Return an item</div>
                <p className="text">Print your return label</p>
                <SalesReturn size={25} color="#bfbfbf" />
              </li>
              <li>
                <div className="head-title">Missed a delivery?</div>
                <p className="text">Arrange a redelivery</p>
                <SongHuo size={35} color="#bfbfbf" />
              </li>
              <li>
                <div className="head-title">Help with your mail</div>
                <p className="text">Visit our help centre</p>
                <SubmitIcon size={25} color="#bfbfbf" />
              </li>
            </ul>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Home;
