import React from "react";
const SubmitIcon = ({
  viewBox = "0 0 24 24",
  color = "rgb(65, 71, 85)",
  size = 24,
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="61458"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M993.735111 148.906667c0.483556-2.986667 1.820444-5.688889 1.820445-8.846223 0-33.536-27.960889-60.728889-62.378667-60.728888-14.165333 0-26.567111 5.376-37.063111 13.169777l-0.597334-1.109333-832.625777 417.28C42.951111 515.584 28.444444 533.788444 28.444444 555.548444c0 22.670222 15.616 41.102222 36.750223 47.388445l252.728889 84.508444 0.455111-1.052444c4.010667 1.336889 8.078222 2.446222 12.572444 2.446222 22.328889 0 40.448-17.664 40.448-39.338667 0-15.217778-9.159111-28.046222-22.129778-34.673777l0.312889-0.711111-192.796444-63.886223 661.048889-331.264-386.104889 422.257778c-11.776 9.728-20.309333 25.457778-20.309334 41.671111 0 4.323556 1.109333 42.609778 1.109334 42.609778v179.655111c0 21.845333 18.090667 39.509333 40.476444 39.509333 22.328889 0 40.419556-17.664 40.419556-39.452444V746.097778l287.175111 96.056889c7.281778 3.896889 15.075556 6.656 23.950222 6.656 27.562667 0 49.891556-21.731556 49.891556-48.668445 0-0.113778-0.312889-0.284444-0.312889-0.398222L995.555556 149.333333l-1.820445-0.426666z m-213.333333 609.763555l-266.780445-89.998222L889.002667 258.872889l-108.600889 499.797333z"
      fill={color}
      p-id="61459"
    ></path>
  </svg>
);
export default SubmitIcon;