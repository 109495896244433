import React from "react";

const AllowUp = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#333",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="39912"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M84.48 648.896C71.456 661.472 64.096 678.752 64 696.832 65.696 737.632 100 769.408 140.8 767.968 161.92 768.064 182.176 759.712 197.12 744.8L512 432.096 826.88 744.8C841.824 759.712 862.08 768.064 883.2 767.968 924 769.408 958.304 737.632 960 696.832 959.904 678.752 952.544 661.472 939.52 648.896L568.32 279.04C537.056 248.32 486.944 248.32 455.68 279.04L84.48 648.896Z"
      fill={color}
      p-id="39913"
    ></path>
  </svg>
);


export default AllowUp;